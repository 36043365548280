<template>
    <div class="files-list" v-if="files.length">
        <b-list-group>
            <b-list-group-item
                class="
          d-flex
          justify-content-between
          align-items-center
          list-group-item list-group-item-action
        "
                v-for="(item, index) in files"
                :key="index"
            ><a :href="item.url" target="_blank">
                <BootstrapIcon icon="file-earmark-text" size="1x"/> {{ item.originalName }}</a>
                <delete-btn v-if="$helper.userCapability('delete',$route)" @pressDelete="deleteFile(item['@id'])"/>
            </b-list-group-item>
        </b-list-group>
    </div>
</template>

<script>
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";

export default {
    components: {
        DeleteBtn,
    },
    props: {
        files: {
            type: Array, default() {
            }
        },
    },
    methods: {
        deleteFile(url) {
            this.$emit("deleteFile", url);
        },
    },
};
</script>

<style>
</style>
