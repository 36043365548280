<template>
    <div
        :class="
      simple ? 'simple-upload' : 'document-upload file-input-component-wrap'
    "
    >
        <div class="file-input-component">
            <div v-if="simple">
                <label class="custom-file-upload">
                    <input
                        id="docfile"
                        ref="docInput"
                        class="documents-list__action-btn"
                        type="file"
                        :accept="accept"
                        @input="fileUpload"
                    />
                    <BootstrapIcon icon="plus" size="1x"/>
                    {{ $t(label) }}
                </label>
            </div>
            <b-form v-else class="document-upload-form" @submit.prevent="onSubmit">
                <p>{{ $t("forms.fileAdd") }}</p>
                <div class="row">
                    <div class="col-sm-4">
                        <b-form-input
                            id="fileLabel"
                            v-model="fileLabel"
                            type="text"
                            :placeholder="$t('forms.documentName')"
                        />
                        <small v-if="labelError" class="text-danger">{{
                                labelError
                            }}</small>
                    </div>
                    <div class="col-sm-4">
                        <input
                            id="docfile"
                            ref="docInput"
                            class="documents-list__action-btn"
                            type="file"
                            :accept="accept"
                        />
                        <small v-if="fileError" class="text-danger">{{ fileError }}</small>
                    </div>
                    <div class="col-sm-4"></div>
                </div>
                <b-button variant="primary" @click="onSubmit">
                    {{ $t(label) }}
                </b-button>
            </b-form>
        </div>
    </div>
</template>

<script>
import ResourceUtils from "@/mixins/ResourceUtils";

export default {
    props: {
        label: {type: String, default: "forms.documentUpload"},
        accept: {type: String, default: ".pdf,.doc"},
        simple: {type: Boolean, default: false},
        tag: {type: String, default: ""},
    },
    mixins: [ResourceUtils],
    data() {
        return {
            error: "",
            fileLabel: "",
            fileError: null,
            labelError: null,
        };
    },
    methods: {
        checkFile() {
            const input = this.$refs.docInput;
            const file = input.files;
            this.fileError = null;
            if (file && file[0]) {
                this.fileError = null;
            } else {
                this.fileError = this.$t("messages.chooseFileError");
            }
            if (this.fileLabel !== "") {
                this.labelError = null;
            } else {
                this.labelError = this.$t("messages.documentNameError");
            }
        },
        fileUpload() {
            const input = this.$refs.docInput;
            const file = input.files;

            const formdata = new FormData();

            formdata.append("file", file[0]);
            formdata.append("label", this.fileLabel);
            if (this.tag !== "") {
                formdata.append("tags", [this.tag]);
            }

            this.create(
                this.$Files,
                formdata,
                this.$t("messages.fileCreated"),
                null,
                this.success
            );
        },
        success(response) {
            this.$emit("fileUploaded", response.data);
        },
        onSubmit() {
            this.checkFile();
            if (!this.fileError && !this.labelError) {
                this.fileUpload();
            }
        },
    },
};
</script>

<style>
</style>
